import 'knockout.validation';
import * as ko from 'knockout';

import { IAddKidRequest, IKid, IStatus } from '../Common/interfaces';
import { Texts } from '../Common/Texts';
import { Utils } from '../Common/Utils';
import Modal from '../Common/Modal';
import { AlertViewModel } from './AlertViewModel';
import { fetch } from '@/Common/fetch';

export class ChildModalViewModel {
    public name: KnockoutObservable<string>;
    public CPR: KnockoutObservable<string>;
    public CPRFormatted: KnockoutObservable<string>;
    public CPRConfirmed: KnockoutObservable<boolean>;
    public showModulus11Warning: KnockoutObservable<boolean>;
    public selectedKidUid: KnockoutObservable<string>;
    public selectedKidToDelete: IKid;
    public inputGroup: KnockoutValidationGroup;

    public alertModal: AlertViewModel;

    constructor() {
        const self = this;
        self.alertModal = new AlertViewModel();

        self.name = ko.observable('').extend({
            required: {
                param: true,
                message: Texts.getResource('PleaseEnterFullName'),
            },
            fullname: {
                param: true,
                message: Texts.getResource('PleaseEnterFullName'),
            },
        });

        self.CPR = ko
            .observable('')
            .extend({ cprClean: true })
            .extend({
                required: {
                    param: true,
                    message: Texts.getResource('CprRequired'),
                },
                validCprLength: true,
                validCprDate: true,
                underCertainAge: 18,
            });

        self.CPR.subscribe((_newValue) => {
            self.showModulus11Warning(false);
            self.CPRConfirmed(false);
        });

        self.CPRConfirmed = ko.observable(false);

        self.inputGroup = ko.validatedObservable([self.name, self.CPR]);
        self.inputGroup.errors.showAllMessages(false);

        self.showModulus11Warning = ko.observable(false);

        self.CPRFormatted = ko.pureComputed(function () {
            return Utils.formatCpr(self.CPR());
        });

        self.selectedKidUid = ko.observable(null);
    }

    public confirmCprIsValid = (): void => {
        this.CPRConfirmed(true);
        if (this.selectedKidUid()) {
            this.editChild();
        } else {
            this.addChild();
        }
    };

    public hideModulus11Warning = (): void => {
        this.showModulus11Warning(false);
    };

    public openAddChildModal = () => {
        this.selectedKidUid(null);
        this.name('');
        this.CPR(null);
        this.setModalTitle();
        this.inputGroup.errors.showAllMessages(false);
        this.showModulus11Warning(false);
        this.CPRConfirmed(false);
        Modal.show('childModal');
    };

    public openEditChildModal = (kid: IKid) => {
        this.name(kid.Name);
        this.CPR(kid.CPR);
        this.selectedKidUid(kid.UID);
        this.setModalTitle();
        this.inputGroup.errors.showAllMessages(false);
        this.showModulus11Warning(false);
        this.CPRConfirmed(false);
        Modal.show('childModal');
    };

    public addChild = async () => {
        if (!this.inputGroup.isValid()) {
            this.inputGroup.errors.showAllMessages(true);
            return;
        }

        if (!Utils.cprModulusCheck(this.CPR()) && !this.CPRConfirmed()) {
            this.showModulus11Warning(true);
            return;
        }

        const request: IAddKidRequest = {
            Name: this.name(),
            CPR: this.CPR(),
        };

        try {
            const response: IStatus<string> = await fetch(
                '/api/patients/child',
                'POST',
                request,
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('CouldNotCreateChild') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('childModal');
                return;
            }

            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('CouldNotCreateChild') + error,
            );
            Modal.close('childModal');
        }
    };

    public editChild = async () => {
        if (!this.inputGroup.isValid()) {
            this.inputGroup.errors.showAllMessages(true);
            return;
        }

        if (!Utils.cprModulusCheck(this.CPR()) && !this.CPRConfirmed()) {
            this.showModulus11Warning(true);
            return;
        }

        const kidToUpdate: IKid = {
            CPR: this.CPR().toString(),
            UID: this.selectedKidUid(),
            Name: this.name(),
        };

        try {
            const response: IStatus<string> = await fetch(
                '/api/patients/kid',
                'PUT',
                kidToUpdate,
            );

            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('CouldNotUpdateChild') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('childModal');
                return;
            }

            window.location.reload();
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('CouldNotUpdateChild') + error,
            );
            Modal.close('childModal');
        }
    };

    public openDeleteKidModal(kidToDelete: IKid): void {
        this.selectedKidToDelete = kidToDelete;
        Modal.show('confirm-modal-kid');
    }

    public closeDeleteKidModal(): void {
        this.selectedKidToDelete = null;
        Modal.close('confirm-modal-kid');
    }

    public deleteChild(): void {
        const successCallback = (
            response: IStatus<string>,
            textStatus: string,
            jqXhr: JQueryXHR,
        ): void => {
            if (response.HasErrors) {
                this.alertModal.openAlert(
                    Texts.getResource('ErrorDeleteChild') +
                        response.Errors.map((e) => e.Value).join('\n'),
                );
                Modal.close('confirm-modal-kid');
                return;
            }
            window.location.reload();
        };

        const errorCallBack = (
            jqXhr: JQueryXHR,
            textStatus: string,
            error: string,
        ): void => {
            this.alertModal.openAlert(
                Texts.getResource('ErrorDeleteChild') + error,
            );
            Modal.close('confirm-modal-kid');
        };

        Utils.ajaxCall(
            'DELETE',
            'api/patients/kid/' + this.selectedKidToDelete.UID,
            successCallback,
            errorCallBack,
            null,
            null,
            false,
        );
    }

    public setModalTitle() {
        const titleElement = document.getElementById('childModal-title');
        if (titleElement) {
            if (this.selectedKidUid() == null) {
                titleElement.textContent =
                    Texts.getResource('Profile_AddChild');
            } else {
                titleElement.textContent =
                    Texts.getResource('Profile_EditChild');
            }
        }
    }
}
