import {
    IPatientUpcomingConsultationDto,
    ISelfResponse,
} from '@/Common/interfaces';
import Modal from '../../Common/Modal';
import { Utils } from '@/Common/Utils';
import { AlertViewModel } from '../AlertViewModel';
import { ReceiveVideoCall } from './ReceiveVideoCallFromDoctor';
import { ConsultationTypeEnum } from '@/Common/enums';
import { ChildModalViewModel } from '@/ViewModels/ChildModalViewModel';
import { PetModalViewModel } from '@/ViewModels/PetModalViewModel';
import { fetch } from '@/Common/fetch';
import { Log } from '@/Common/Log';

export class SelectPatientViewModel {
    public receiveVideoCall: ReceiveVideoCall;
    public profileState: KnockoutObservable<string>;
    public childModal: ChildModalViewModel;
    public petModal: PetModalViewModel;
    public alertModal: AlertViewModel;
    public usersUpcomingBookingConsultationMap: IPatientUpcomingConsultationDto[];

    constructor(consultationType: ConsultationTypeEnum) {
        const self = this;
        self.usersUpcomingBookingConsultationMap = [];
        self.alertModal = new AlertViewModel();
        self.receiveVideoCall = new ReceiveVideoCall();
        self.childModal = new ChildModalViewModel();
        self.petModal = new PetModalViewModel();

        fetch(
            `/api/patients/has-upcoming-video-consultation-per-user/${consultationType}`,
            'GET',
        )
            .then((response: IPatientUpcomingConsultationDto[]) => {
                this.usersUpcomingBookingConsultationMap = response;
            })
            .catch((err) => {
                Log.error(
                    'Error when fetching upcoming consultations for patient',
                    null,
                    err,
                );
            });
    }

    public validateIfPatientHaveUpcomingConsultationBeforeContinuing = (
        continuationUrl: string,
        patientUid: string,
    ) => {
        const self = this;
        const selectedUser = self.usersUpcomingBookingConsultationMap.find(
            (x) => x.UID == patientUid,
        );
        if (selectedUser && selectedUser.HasUpcomingVideoConsultation) {
            Modal.show('youAlreadyHaveUpcomingConsultation');
            return;
        }

        document.location.href = continuationUrl;
    };

    public closeYouAlreadyHaveUpcomingConsultation = () => {
        Modal.close('youAlreadyHaveUpcomingConsultation');
    };
}
