import {
    addDays,
    format,
    intervalToDuration,
    isToday,
    parseISO,
    startOfToday,
} from 'date-fns';
import Dropzone, { DropzoneOptions } from 'dropzone';
import * as ko from 'knockout';
import 'knockout.validation';
import {
    ConsultationTypeEnum,
    OpenVideoConsultationStatusEnum,
} from '../../Common/enums';
import { fetch as commonFetch } from '@/Common/fetch';
import {
    IAttachment,
    IConsultationDto,
    IConsultationMessageAttachmentDto,
    IConsultationMessageDto,
    IContinueMessageConsultationRequest,
    IGetScheduleRequest,
    IOpenMessageConsultationResponse,
    IRescheduleVideoConsultationRequest,
    IRescheduleVideoConsultationResult,
    ITimelineMessage,
    ITimeSlotDto,
    ResponseError,
} from '../../Common/interfaces';
import { Log } from '../../Common/Log';
import Modal from '../../Common/Modal';
import { Texts } from '../../Common/Texts';
import { Utils } from '../../Common/Utils';
import { AlertViewModel } from '../AlertViewModel';
import { ReceiveVideoCall } from './ReceiveVideoCallFromDoctor';

interface ITimeslotsByDay {
    day: string;
    timeslots: Date[];
}

const emptyUuid = '00000000-0000-0000-0000-000000000000';

export class ConsultationDetailsViewModel {
    public arrowImagePath = ko.observable('/images/icons/chevron-down.svg');
    public confirmMessage = ko.observable('');
    public daysTillWaitingRoomOpens = ko.observable(0);
    public hoursTillWaitingRoomOpens = ko.observable(0);
    public minutesTillWaitingRoomOpens = ko.observable(0);
    public showWaitingRoomOpen: KnockoutObservable<boolean>;
    public showCountdown: KnockoutObservable<boolean>;
    public showDays = ko.observable(false);
    public showMinutes = ko.observable(true);
    private linkToCancellation: string;
    public receiveVideoCall: ReceiveVideoCall;
    public alertModal: AlertViewModel;
    public messages = ko.observableArray<ITimelineMessage>([]);
    public textMessage: KnockoutObservable<string>;
    public attachments: KnockoutObservableArray<string>;
    private isBooking: boolean;
    public isPaid = ko.observable(true);
    public shouldPay = ko.observable(true);
    public error: KnockoutValidationGroup;
    public showWaitingForReplyInfo: KnockoutObservable<boolean>;
    public isVideo: KnockoutObservable<boolean>;
    private consultationUid: string;
    private dropzone: Dropzone;
    private dropzoneCleaner: () => void;
    private existingMessageUuid: string;
    private isSending = false;
    public isSendMessageButtonDisabled: KnockoutComputed<boolean>;
    public errorMessages = ko.observableArray<string>([]);
    public preferredCaregiver = ko.observable(emptyUuid);
    private consultationDraftScheduledOn: string | null = null;
    public selectedTimeslot = ko.observable<Date>(null);
    public hasLoadedTimeslots = ko.observable(false);
    private consultationType: ConsultationTypeEnum;
    public firstAvailableTimeslot = ko.observable<Date>(null);
    public firstAvailableTimeslotFormatted = ko.observable<string>(null);
    public showTimeZoneWarning =
        Intl.DateTimeFormat().resolvedOptions().timeZone != 'Europe/Copenhagen';
    public timeZoneWarning = Texts.get('TimeZoneWarning', {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    // rateLimit prevents updating the timeslotsByDay computed on every insert
    private allTimeSlots = ko.observableArray<ITimeSlotDto>();

    public timeslots = ko.computed<Date[]>(() => {
        const preferredCaregiver = this.preferredCaregiver();
        let timeslots = this.allTimeSlots();
        if (preferredCaregiver !== emptyUuid) {
            timeslots = timeslots.filter((x) => {
                return x.AvailableCaregivers.includes(preferredCaregiver);
            });
        }
        const arrayOfTimeSlots = timeslots.map((x) => parseISO(x.Start));

        if (
            arrayOfTimeSlots.length > 0 &&
            this.consultationDraftScheduledOn != null
        ) {
            const consultationDraftScheduledOnFormatted = parseISO(
                this.consultationDraftScheduledOn,
            );
            const timeSlotDraft = arrayOfTimeSlots.find(
                (t) =>
                    t.toJSON() ==
                    consultationDraftScheduledOnFormatted.toJSON(),
            );

            if (timeSlotDraft) {
                this.selectedTimeslot(timeSlotDraft);
            }
        }

        return arrayOfTimeSlots;
    });

    public timeslotsByDay = ko.computed<ITimeslotsByDay[]>(() => {
        const timeslotMap: { [day: string]: Date[] } = {};

        // group the timeslots by date in a map
        this.timeslots().reduce((result, currentItem) => {
            const key = isToday(currentItem)
                ? Texts.getResource('Today')
                : format(currentItem, 'eeee') +
                  ', ' +
                  Utils.formatLongDate(currentItem);
            (result[key] = result[key] || []).push(currentItem);
            return result;
        }, timeslotMap);

        // convert the map to an array to simplify knockout rendering
        const timeslotArray: ITimeslotsByDay[] = [];

        for (const key in timeslotMap) {
            const value = timeslotMap[key];
            timeslotArray.push({ day: key, timeslots: value });
        }

        return timeslotArray;
    });

    public currentTimeslotDay = ko.observable<number>(0);

    public visibleTimeslotsPerDay = ko.computed<ITimeslotsByDay>(() => {
        return this.timeslotsByDay()[this.currentTimeslotDay()];
    });

    constructor(
        consultation: IConsultationDto,
        consultationType: ConsultationTypeEnum,
        existingMessageUuid: string | null = null,
        message = '',
        attachments: string[] = [],
        canReply = false,
        isVideo = false,
    ) {
        const self = this;
        self.receiveVideoCall = new ReceiveVideoCall();

        const now = new Date();
        const opensOn = parseISO(consultation.OpensOn);
        const closesOn = parseISO(consultation.ClosesOn);
        self.showWaitingRoomOpen = ko.observable(
            opensOn < now && now < closesOn,
        );
        self.showCountdown = ko.observable(now < opensOn);
        self.updateTime(opensOn, closesOn);
        setInterval(self.updateTime.bind(self), 1_000, opensOn, closesOn);
        self.existingMessageUuid = existingMessageUuid;
        self.consultationUid = consultation.UID;
        self.alertModal = new AlertViewModel();
        self.attachments = ko.observableArray(attachments);
        self.textMessage = ko.observable(message).extend({
            required: {
                message: ' ',
            },
        });
        self.isVideo = ko.observable(isVideo);
        self.isSendMessageButtonDisabled = ko.computed(() => {
            return this.textMessage().trim() === '';
        });
        self.consultationType = consultationType;

        if (canReply) {
            const dropzoneOptions: DropzoneOptions = {
                url: '/api/files/upload',
                method: 'POST',
                success: (file: any) => {
                    const uid = JSON.parse(file.xhr.responseText);
                    (file as any).uid = uid;
                    this.attachments.push(uid);
                },
                init: function () {
                    this.on('addedfile', function (file) {
                        if (file.size > dropzoneOptions.maxFilesize) {
                            const newErrorMessage = `${Texts.getResource(
                                'UploadFailedTooLarge',
                            ).replace('{0}', file.name)}`;
                            self.errorMessages.push(newErrorMessage);
                            self.dropzone.removeFile(file);
                            return;
                        }

                        const dropzoneContainer =
                            document.getElementById('dropzone');

                        const previewElements =
                            document.querySelectorAll('.dz-preview');
                        if (previewElements.length !== 0) {
                            dropzoneContainer.style.height = 'auto';
                        }

                        // Show "Add more" icon
                        const existingAddFilesContainer =
                            document.getElementById('addFilesContainer');
                        if (existingAddFilesContainer) {
                            existingAddFilesContainer.remove();
                        }

                        const addFilesContainer = document.createElement('div');
                        addFilesContainer.id = 'addFilesContainer';
                        addFilesContainer.className =
                            'inline-block mt-1 mr-1 mb-2 ml-1 border border-grey-100 hover:bg-grey-100';
                        addFilesContainer.style.borderRadius = '20px';
                        addFilesContainer.style.width = '80px';
                        addFilesContainer.style.height = '80px';
                        addFilesContainer.onclick = function () {
                            self.uploadAttachment();
                        };

                        const alignmentWrapper = document.createElement('div');
                        alignmentWrapper.className =
                            'flex flex-col h-full items-center justify-center';
                        alignmentWrapper.style.cursor = 'pointer';

                        const addFilesIcon = document.createElement('img');
                        addFilesIcon.src =
                            '/images/icons/attachment-plus-icon.svg';
                        addFilesIcon.style.width = '24px';
                        addFilesIcon.style.height = '24px';
                        addFilesIcon.style.cursor = 'pointer';

                        const addFilesText = document.createElement('div');
                        addFilesText.className = 'text-sm mt-2';
                        addFilesText.textContent = Texts.getResource('AddMore');
                        addFilesText.style.cursor = 'pointer';

                        alignmentWrapper.appendChild(addFilesIcon);
                        alignmentWrapper.appendChild(addFilesText);
                        addFilesContainer.appendChild(alignmentWrapper);
                        dropzoneContainer.appendChild(addFilesContainer);

                        // Show "Remove file" icon
                        const customRemoveLink =
                            document.createElement('button');
                        customRemoveLink.className =
                            'p-0 border-0 bg-transparent dz-remove absolute mt-1 mr-1 z-[999] top-0 right-0';
                        customRemoveLink.innerHTML =
                            '<img style="cursor: pointer;" src="/images/icons/remove-file-icon.svg" height="24" width="24">';
                        customRemoveLink.style.height = '24px';

                        file.previewElement.appendChild(customRemoveLink);

                        customRemoveLink.addEventListener('click', function () {
                            self.dropzone.removeFile(file);

                            // Remove outdated error messages
                            self.errorMessages.removeAll();

                            // Remove 'Add more' icon, if no files are selected
                            const previewElements =
                                document.querySelectorAll('.dz-preview');
                            if (previewElements.length === 0) {
                                const addFilesContainer =
                                    document.getElementById(
                                        'addFilesContainer',
                                    );
                                if (addFilesContainer) {
                                    addFilesContainer.remove();
                                }

                                // Hide dropzone, if no files are selected
                                const dropzoneContainer =
                                    document.getElementById('dropzone');
                                dropzoneContainer.style.height = '0px';
                            }
                        });
                    });
                    this.on('error', function (file, message) {
                        self.dropzone.removeFile(file);
                        const newErrorMessage = message.toString();

                        // Only show 'MaxFilesExceeded' error message once
                        self.errorMessages.remove(newErrorMessage);
                        self.errorMessages.push(newErrorMessage);
                    });
                },
                addRemoveLinks: false,
                acceptedFiles: 'image/jpeg,image/png,image/gif,.pdf,.mov',
                maxFiles: 10,
                maxFilesize: 30_000_000,
                dictMaxFilesExceeded: Texts.getResource('MaxFilesExceeded'),
                dictInvalidFileType: Texts.getResource('InvalidFileType'),
            };

            self.dropzone = new Dropzone('#dropzone', dropzoneOptions);

            self.dropzone.on('removedfile', (file: any) => {
                self.attachments.remove(file.uid);
            });

            self.dropzoneCleaner = () => {
                self.dropzone.removeAllFiles(true);
                $('#dropzone').empty();
            };

            if (existingMessageUuid !== null) {
                fetch(`/api/files/attachments/${existingMessageUuid}`)
                    .then((response) => response.json())
                    .then(
                        (
                            data: Array<{
                                FileName: string;
                                FileByteSize: number;
                                UID: string;
                            }>,
                        ) => {
                            data.forEach((value) => {
                                const mockFile = {
                                    name: value.FileName,
                                    size: value.FileByteSize,
                                };
                                self.dropzone.emit('addedfile', mockFile);
                                self.dropzone.options.thumbnail.call(
                                    self.dropzone,
                                    mockFile,
                                    '/api/files/' + value.UID,
                                );
                                self.dropzone.emit('complete', mockFile);
                            });
                        },
                    )
                    .catch((error) => Log.error('Error fetching data:', error));
            }

            document
                .getElementById('attachment-input')
                .addEventListener('change', function () {
                    $('.js-send-button').prop('disabled', true);
                    $('.js-upload-anchor').prop('disabled', true);
                    const input = this as HTMLInputElement;
                    for (let i = 0; i < input.files.length; i++) {
                        const file = input.files.item(i);
                        const fd = new FormData();
                        fd.append('file', file);
                        $.ajax({
                            method: 'POST',
                            url: '/api/files/upload',
                            success: (data) => {
                                self.attachments.push(data);
                                $('.js-send-button').prop('disabled', false);
                                $('.js-upload-anchor').prop('disabled', false);
                            },
                            error: (data) => {
                                self.alertModal.openAlert(
                                    'Error occured while uploading file',
                                );
                                $('.js-send-button').prop('disabled', false);
                                $('.js-upload-anchor').prop('disabled', false);
                            },
                            contentType: false,
                            processData: false,
                            accepts: { '*': '' },
                            data: fd,
                        });
                    }
                });
        }

        self.error = ko.validatedObservable(this);
        self.error.errors.showAllMessages(false);
        self.initConsultation(consultation);
        self.showWaitingForReplyInfo = ko.computed(() => {
            const consultationLength = this.messages().length;

            if (consultationLength > 0) {
                const latestMessage = this.messages()[consultationLength - 1];
                return !latestMessage.isDoctorType;
            }

            return false;
        });
    }

    public nextDayClicked() {
        this.currentTimeslotDay(this.currentTimeslotDay() + 1);
    }

    public previousDayClicked() {
        this.currentTimeslotDay(this.currentTimeslotDay() - 1);
    }

    public isPreviousDayButtonVisible = ko.computed<boolean>(() => {
        return this.currentTimeslotDay() > 0;
    });

    public isNextDayButtonVisible = ko.computed<boolean>(() => {
        return !!this.timeslotsByDay()[this.currentTimeslotDay() + 1];
    });

    // for unknown reasons `this` is set to the date parameter on click
    // so we have to pass in a reference to the view model manually
    public selectTimeslot(
        self: ConsultationDetailsViewModel,
        date: Date,
    ): void {
        self.selectedTimeslot(date);
    }

    public toggleBookingConfirmation(): void {
        const button = document.getElementById(
            'toggleBookingConfirmationButton',
        );
        const panel = button.nextElementSibling as HTMLElement;

        if (panel.style.maxHeight != '0px') {
            this.arrowImagePath('/images/icons/chevron-down.svg');
            panel.style.maxHeight = '0px';
        } else {
            this.arrowImagePath('/images/icons/chevron-up.svg');
            panel.style.maxHeight = panel.scrollHeight + 'px';
        }

        button.blur();
    }

    public cancelConsultation(): void {
        document.location.href = this.linkToCancellation;
    }

    public openConfirmCancelModal(
        linkToCancellation: string,
        consultationScheduledOn: string,
        minutesToMeetingAfterWhichBookingCantBeRefunded: number,
        confirmCancelMessage: string,
    ): void {
        this.linkToCancellation = linkToCancellation;

        this.confirmMessage('');
        const scheduledOn = new Date(consultationScheduledOn).getTime();
        const now = new Date().getTime();
        const totalMinutes = Math.floor((scheduledOn - now) / 1000 / 60);
        const itIsBitLateForCancellation =
            totalMinutes < minutesToMeetingAfterWhichBookingCantBeRefunded;
        if (itIsBitLateForCancellation) {
            this.confirmMessage(confirmCancelMessage);
        }

        Modal.show('confirm-cancel-modal');
    }

    public closeConfirmModal(): void {
        Modal.close('confirm-cancel-modal');
    }

    public openRescheduleConsultationModal(): void {
        this.getSchedule();
        Modal.show('reschedule-consultations-modal');
    }

    public closeRescheduleConsultationModal(): void {
        Modal.close('reschedule-consultations-modal');
    }

    private updateTime(
        waitingRoomOpensOn: Date,
        waitingRoomClosesOn: Date,
    ): void {
        const now = new Date();

        const duration = intervalToDuration({
            start: now,
            end: waitingRoomOpensOn,
        });

        this.daysTillWaitingRoomOpens(duration.days);
        this.hoursTillWaitingRoomOpens(duration.hours);
        this.minutesTillWaitingRoomOpens(duration.minutes + 1);

        this.showDays(this.daysTillWaitingRoomOpens() > 0);
        this.showMinutes(!this.showDays());

        this.showWaitingRoomOpen(
            waitingRoomOpensOn < now && now < waitingRoomClosesOn,
        );
        this.showCountdown(now < waitingRoomOpensOn);
    }

    public uploadAttachment = () => {
        // Remove outdated error messages
        this.errorMessages.removeAll();

        $('#dropzone').trigger('click');
    };

    public sendMessage(): void {
        const self = this;

        if (!self.error.isValid()) {
            self.error.errors.showAllMessages();
            return;
        }

        if (self.isSending) {
            return;
        }

        self.isSending = true;

        const errorCallback = (
            response: JQueryXHR,
            status: string,
            error: string,
        ): void => {
            self.isSending = false;
            switch (response.status) {
                case 402:
                    self.alertModal.openAlert(
                        Texts.getResource('InvalidPayment'),
                    );
                    break;
                default:
                    self.alertModal.openAlert(
                        Texts.getResource('ErrorSendingMessage'),
                    );
                    break;
            }
        };

        const successCallback = (
            response: IOpenMessageConsultationResponse,
            status: string,
            jqXhr: JQueryXHR,
        ): void => {
            if (response.RequiresPayment) {
                window.location.href =
                    window.location.origin +
                    '/payment/plan?consultation=' +
                    response.ConsultationMessageGuid;
            } else {
                window.location.href =
                    window.location.origin +
                    '/consultations/' +
                    response.ConsultationMessageGuid +
                    '/sentemail';
            }
        };

        self.responseToDoctor(successCallback, errorCallback);
    }

    public responseToDoctor(
        successCallback: (
            response: IOpenMessageConsultationResponse,
            status: string,
            jqXhr: JQueryXHR,
        ) => any,
        errorCallback: (
            response: JQueryXHR,
            status: string,
            error: string,
        ) => any,
    ): void {
        const self = this;

        const request: IContinueMessageConsultationRequest = {
            ExistingMessageGuid: self.existingMessageUuid,
            Message: self.textMessage(),
            FileUids: self.attachments(),
        };

        Utils.ajaxCall(
            'POST',
            'api/consultations/' + self.consultationUid + '/messages/v3',
            successCallback,
            errorCallback,
            JSON.stringify(request),
        );
    }

    private initConsultation(consultation: IConsultationDto): void {
        const self = this;

        self.isPaid(consultation.PaidOn !== null);
        self.shouldPay(consultation.RequiresPayment);

        const messages: IConsultationMessageDto[] = self.isVideo()
            ? consultation.VideoMessages
            : consultation.Messages;

        messages.forEach((item: IConsultationMessageDto) => {
            const isDoctor = item.FromUser === null;
            if (!item.PaymentVerificationData.PaidOn && !isDoctor) {
                return;
            }
            const currentTimelineMessage: ITimelineMessage = {
                userUid: item.FromUser == null ? null : item.FromUser.UID,
                // naive link detection
                message: item.Message.replace(
                    /(\b(https?):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gi,
                    '<a href="$1" style="color:inherit" class="underline font-medium" target="_blank">$1</a>',
                ).trim(),
                isDoctorType: isDoctor,
                sentOn: item.SentOn,
                attachments: item.Attachments.map(
                    (attachment: IConsultationMessageAttachmentDto) => {
                        const file: IAttachment = {
                            fileUid: attachment.File.UID,
                            fileName: this.truncateFileName(
                                attachment.File.FileName,
                            ),
                            canShowPreview: this.canShowPreview(
                                attachment.File.FileName,
                            ),
                        };
                        return file;
                    },
                ).sort(
                    (a, b) =>
                        // place attachments with no preview on bottom
                        Number(b.canShowPreview) - Number(a.canShowPreview),
                ),
            };
            self.messages.push(currentTimelineMessage);
        });
    }

    private canShowPreview(fileName: string): boolean {
        const pathParts = fileName.split('.');
        const fileType = pathParts[pathParts.length - 1];
        return (
            fileType === 'png' ||
            fileType === 'jpeg' ||
            fileType === 'gif' ||
            fileType === 'jpg'
        );
    }

    private truncateFileName(fileName: string): string {
        const maxLength = 20;
        const cutOffPoint = 8;

        if (fileName.length < maxLength) {
            return fileName;
        }

        const start = fileName.substring(0, cutOffPoint);
        const end = fileName.substring(fileName.length - cutOffPoint);
        const middle = '....';
        return `${start}${middle}${end}`;
    }

    public autoExpandHeight(): void {
        const textarea = document.getElementById('textMessage');

        if (!textarea) return;

        textarea.style.height = 'auto'; // decrease height when text is removed
        textarea.style.height = textarea.scrollHeight + 'px'; // increase height when text is added
        textarea.style.borderRadius =
            textarea.scrollHeight > 39 ? '16px' : '28px';
    }

    private async getSchedule(): Promise<void> {
        const startingDate = startOfToday();
        const endingDate = addDays(new Date(), 6);

        const request: IGetScheduleRequest = {
            FromDate: startingDate.toJSON(),
            ToDate: endingDate.toJSON(),
            ConsultationType: this.consultationType,
            ConsultationDraftScheduledOn: this.consultationDraftScheduledOn,
            CurrentConsultationUid: this.consultationUid,
        };

        try {
            const query = Object.entries(request)
                .map(([key, value]) => key + '=' + encodeURIComponent(value))
                .join('&');
            const response: ITimeSlotDto[] = await commonFetch(
                '/api/consultations/video/schedule/v2?' + query,
            );

            this.allTimeSlots.removeAll();
            this.allTimeSlots.push(...response);
            this.hasLoadedTimeslots(true);
            if (this.allTimeSlots().length > 0) {
                const firstTimeslot = parseISO(this.allTimeSlots()[0].Start);

                this.firstAvailableTimeslot(firstTimeslot);
                this.firstAvailableTimeslotFormatted(
                    Utils.formatLongDateTime(this.allTimeSlots()[0].Start),
                );
            }
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('ErrorFetchingTimetable'),
            );
        }
    }

    public executeScheduleConsultationOnSpecificDate() {
        this.rescheduleConsultationOnSpecificDate();
    }

    public rescheduleConsultationOnSpecificDate(): void {
        if (this.selectedTimeslot() == null) {
            this.alertModal.openAlert(Texts.getResource('PleaseChooseDate'));
            return;
        }
        $('.js-schedule-btn').addClass('button--loading');

        this.rescheduleVideoConsultation();
    }

    public async rescheduleVideoConsultation(): Promise<void> {
        const self = this;

        if (self.isBooking) {
            return;
        }

        self.isBooking = true;

        const request: IRescheduleVideoConsultationRequest = {
            UID: this.consultationUid,
            PreferredCaregiverUid: this.preferredCaregiver(),
            ConsultationType: this.consultationType,
        };
        if (this.selectedTimeslot() != null) {
            request.ScheduledOn = this.selectedTimeslot();
        }

        try {
            const response: IRescheduleVideoConsultationResult =
                await Utils.fetch<IRescheduleVideoConsultationResult>(
                    '/api/consultations/reschedule-video',
                    'POST',
                    JSON.stringify(request),
                    'application/json',
                );
            if (response.Status == 0) {
                this.callbackSucces(response);
            }
        } catch (error) {
            this.isBooking = false;
            $('.js-schedule-btn').removeClass('button--loading');
            if ((error as ResponseError).status == 409) {
                if (
                    error.data.Status ==
                    OpenVideoConsultationStatusEnum.TooLateToReschedule
                ) {
                    self.alertModal.openAlert(
                        Texts.getResource('TooLateToReschedule'),
                    );
                } else {
                    self.alertModal.openAlert(
                        Texts.getResource('TimeSlotUnavailable'),
                    );
                    self.getSchedule();
                }
            } else {
                this.alertModal.openAlert(
                    Texts.getResource('ErrorSchedulingConsultation'),
                );
            }
        }
    }
    private callbackSucces(response: IRescheduleVideoConsultationResult) {
        if (response.Status === 0 && response.ConsultationGuid) {
            window.location.reload();
        }
    }
}
